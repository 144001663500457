import React from "react";
import { Player } from "../../store/players/models";
import { format, parse, isValid } from "date-fns";
import { useAuth0 } from "../shell/Auth0Context";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface PlayerProfileProps {
  player: Player;
  onPlayerChanged: (p: Player) => void;
}

const PlayerProfile: React.FC<PlayerProfileProps> = ({
  player,
  onPlayerChanged,
}) => {
  const { user } = useAuth0();

  const editedPlayer: Player = player || {
    birthDate: format(new Date(), "yyyy-MM-dd"),
    email: user?.email,
    gender: "N",
    id: undefined,
    joined: undefined,
    level: "N",
    name: "",
    phoneNumber: "",
    //preferredClubs: undefined,
    //preferredTime: "",
    surname: "",
  };

  const setStringField = (field: keyof Player) => (event: any) => {
    onPlayerChanged({ ...editedPlayer, [field]: event.target.value });
  };

  const setDateField = (field: keyof Player) => (
    value: MaterialUiPickersDate
  ) => {
    if (isValid(value)) {
        onPlayerChanged({ ...editedPlayer, [field]: !!value ? format(value, "yyyy-MM-dd") : "" })
    }
  };

  const fromStringDate = (field: keyof Player) => {
    if (!editedPlayer[field]) {
      return new Date();
    } else {
      return parse(
        editedPlayer[field]?.toString() || "",
        "yyyy-MM-dd",
        new Date()
      );
    }
  };

  return (
    <form autoComplete="off" noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            style={{ width: "100%" }}
            label="Imię"
            error={!editedPlayer?.name}
            variant="outlined"
            value={editedPlayer.name}
            onChange={setStringField("name")}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            style={{ width: "100%" }}
            label="Nazwisko"
            error={!editedPlayer.surname}
            variant="outlined"
            value={editedPlayer.surname}
            onChange={setStringField("surname")}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <KeyboardDatePicker
            inputVariant="outlined"
            variant="inline"
            style={{ width: "100%" }}
            format="yyyy-MM-dd"
            margin="normal"
            id="date-picker-inline"
            label="Data urodzenia"
            autoOk
            value={fromStringDate("birthDate")}
            onChange={setDateField("birthDate")}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            margin="normal"
          >
            <InputLabel id="gender">Płeć</InputLabel>
            <Select
              labelId="gender"
              id="gender-field"
              onChange={setStringField("gender")}
              value={editedPlayer.gender}
              labelWidth={32}
            >
              <MenuItem value={"M"}>Mężczyzna</MenuItem>
              <MenuItem value={"K"}>Kobieta</MenuItem>
              <MenuItem value={"N"}>Nie podaję</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            margin="normal"
          >
            <InputLabel id="level">Poziom NTRP</InputLabel>
            <Select
              labelId="level"
              id="level-field"
              onChange={setStringField("level")}
              value={editedPlayer.level}
              labelWidth={100}
            >
              <MenuItem value={"1.0"}>1.0</MenuItem>
              <MenuItem value={"1.5"}>1.5</MenuItem>
              <MenuItem value={"2.0"}>2.0</MenuItem>
              <MenuItem value={"2.5"}>2.5</MenuItem>
              <MenuItem value={"3.0"}>3.0</MenuItem>
              <MenuItem value={"3.5"}>3.5</MenuItem>
              <MenuItem value={"4.0"}>4.0</MenuItem>
              <MenuItem value={"4.5"}>4.5</MenuItem>
              <MenuItem value={"5.0"}>5.0</MenuItem>
              <MenuItem value={"5.5"}>5.5</MenuItem>
              <MenuItem value={"6.0"}>6.0</MenuItem>
              <MenuItem value={"6.5"}>6.5</MenuItem>
              <MenuItem value={"7.0"}>7.0</MenuItem>
              <MenuItem value={"N"}>Nie podaję</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            style={{ width: "100%" }}
            label="Numer telefonu"
            variant="outlined"
            error={!editedPlayer.phoneNumber}
            value={editedPlayer.phoneNumber}
            onChange={setStringField("phoneNumber")}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            style={{ width: "100%" }}
            label="Adres email"
            variant="outlined"
            error={!editedPlayer.email}
            value={editedPlayer.email || ""}
            onChange={setStringField("email")}
          ></TextField>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{ display: "flex", flexFlow: "row", alignItems: "center" }}
        >
          <Link href="./NTRP-PL.pdf" target="_blank">
            Opis poziomów NTRP
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default PlayerProfile;
