import { AnyAction } from "redux";

export interface ActionWithPayload<T> extends AnyAction {
    payload: T;
}

export interface ActionWithError extends AnyAction {
    error: string;
    message: string;
    severity: ErrorSeverity;
    additionalInfo?: any;
}

export interface TokenAware {
    token: string;   
}

export enum ErrorSeverity {
    INFO,
    WARNING,
    ERROR,
    CRITCAL,
    NONE
}

export interface WithNotification {
    notification: Notification;
}

export interface Notification {
    severity: Severity;
    message: string;
}

export enum Severity {
    Success = "success",
    Error = "error",
    Warning = "warning",
    Info = "info",
}

export type ActionWithPayloadCreator<T extends ActionWithPayload<V>, V = any> = (v: V) => T;
export type ActionWithErrorCreator<T extends ActionWithError> = (error: string) => T;

export interface ProvinceAware {
    province: number;
}

export interface IdAware {
    id: string;
}