import { getConfig } from "../../config/configuration";
import { Player } from "./models";

export async function loadCurrentPlayerApi(token: string) {
    const config = await getConfig();

    const response = await fetch(`${config.apiUrl}/players/me`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });

    if (response.ok) {
        const player: Player = await response.json();
        return player;
    } else if (response.status === 404) {
        return null;
    } else {
        throw new Error(response.statusText);
    }
}

export async function createPlayerApi(player: Player, token: string): Promise<Player> {
    const config = await getConfig();

    const response = await fetch(`${config.apiUrl}/players/me`, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(player)
    });

    if (response.ok) {
        const player: Player = await response.json();
        return player
    } else {
        throw new Error(response.statusText);
    }
}

export async function updatePlayerApi(player: Player, token: string): Promise<Player> {
    const config = await getConfig();

    const response = await fetch(`${config.apiUrl}/players/me`, {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(player)
    });

    if (response.ok) {
        const player: Player = await response.json();
        return player
    } else {
        throw new Error(response.statusText);
    }
}