import { LOAD_CURRENT_PLAYER, CREATE_PLAYER, UPDATE_PLAYER } from "./actionTypes";
import { takeEvery, put, call } from "redux-saga/effects";
import { LoadCurrentPlayer, loadCurrentPlayerFailed, loadCurrentPlayerSuccess, CreatePlayer, createPlayerSuccess, createPlayerFailed, UpdatePlayer, updatePlayerSuccess, updatePlayerFailed } from "./actions";
import { loadCurrentPlayerApi, createPlayerApi, updatePlayerApi } from "./api";
import { Player } from "./models";

function* loadCurrentPlayerStep({ payload }: LoadCurrentPlayer) {
    var token = payload.token;

    try {

        const player = yield call(loadCurrentPlayerApi, token);
        yield put(loadCurrentPlayerSuccess(player))

    } catch (e) {
        yield put(loadCurrentPlayerFailed(e.message));
    }
}

function* createPlayerStep({ payload }: CreatePlayer) {
    var token = payload.token;

    try {
        const player = yield call(createPlayerApi, <Player>{ 
            birthDate: payload.birthDate, 
            email: payload.email, 
            gender: payload.gender, 
            name: payload.name, 
            level: payload.level, 
            phoneNumber: payload.phoneNumber, 
            surname: payload.surname }, token);
        yield put(createPlayerSuccess(player));
    } catch(e) {
        yield put(createPlayerFailed(e.message));
    }
}

function* updatePlayerStep({ payload }: UpdatePlayer) {
    var token = payload.token;
    debugger;

    try {
        const player = yield call(updatePlayerApi, <Player>{ 
            id: payload.id,
            birthDate: payload.birthDate, 
            email: payload.email, 
            gender: payload.gender, 
            name: payload.name, 
            level: payload.level, 
            phoneNumber: payload.phoneNumber, 
            surname: payload.surname }, token);
        yield put(updatePlayerSuccess(player));
    } catch(e) {
        yield put(updatePlayerFailed(e.message));
    }
}

export function* loadCurrentPlayerSaga() {
    yield takeEvery(LOAD_CURRENT_PLAYER, loadCurrentPlayerStep);
}

export function* createPlayerSaga() {
    yield takeEvery(CREATE_PLAYER, createPlayerStep);
}

export function* updatePlayerSaga() {
    yield takeEvery(UPDATE_PLAYER, updatePlayerStep);
}