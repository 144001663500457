import React from "react";
import { useAuth0 } from "./Auth0Context";
import { Button, Menu, MenuItem } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "../../store/reducer";
import { loadCurrentPlayer } from "../../store/players/actions";
import { Link } from "react-router-dom";
import { Data } from "../../framework/data";
import { Player } from "../../store/players/models";

interface AccountMenuProps {
  loadCurrentPlayer: (token: string) => void;
  player: Data<Player>;
}

const AccountMenu: React.FC<AccountMenuProps> = ({ loadCurrentPlayer, player }) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently,
  } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!!user) {
      setAnchorEl(event.currentTarget);
    } else {
      //logger.logEvent("Login attempt");
      loginWithRedirect({ redirect_uri: window.location.href });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    async function getToken() {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        loadCurrentPlayer(token);
      }
    }

    getToken();
  }, [isAuthenticated]);

  return (
    <>
      <Button onClick={handleClick}>
        <AccountCircleIcon color={"secondary"}></AccountCircleIcon>
        <span
          style={{
            display: "inline-block",
            maxWidth: "110px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {!!user ? user.nickname : "zaloguj się"}
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isAuthenticated && player.type === "LOADED" && player.value && (
          <MenuItem component={Link} to="/payments">
            Moje płatności
          </MenuItem>
        )}
        {isAuthenticated && (
          <MenuItem
            onClick={() => {
              handleClose();
              logout({ returnTo: window.location.href });
            }}
          >
            Wyloguj
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  player: state.players.current
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadCurrentPlayer: (token: string) => dispatch(loadCurrentPlayer({ token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
