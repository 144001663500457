import { Player } from "../players/models";

export interface AdditionalSignupModel {
    tocApproved: boolean;
    promo: string;
    referral: string;
}

export interface PriceDetails {
    tournamentName: string;
    basePrice: number;
    calculatedPrice: number;
    discounts: DiscountLine[];
    additionalInfo: string;
}

export interface DiscountLine {
    description: string;
    discountName: string;
    discount: string;
}

export enum SignupStatus {
    NOT_STARTED,
    IN_PROGRESS,
    FINISHED,
    FAILED
}

export interface SignupNotification {
    isError: boolean;
    message: string;
    additionalInfo?: any;
    code?: string;
}

export interface TournamentSignupPayload {
    player: Player;
    tournamentId: string;
    tournamentProvince: number;
    additionalModel: AdditionalSignupModel;
    token: string;
}