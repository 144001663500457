import { Overwrite } from "../tournaments/models";

export interface PaymentInfoResponse {
    externalPaymentOrderId: string;
    paymentDate: string;
    paymentStatus:PaymentStatus;
    finalPrice: number;
    tournamentId: string;
    tournamentProvince: number;
    tournamentName: string;
}

export type PaymentInfo = Overwrite<PaymentInfoResponse, {paymentDate: Date}>;

export enum PaymentStatus {
    PENDING,
    WAITING_FOR_CONFIRMATION,
    COMPLETED,
    CANCELED
}

export interface PaymentOrder {
    orderId: string;
    redirectUri: string;
    finalPrice: number;
}