import { TokenAware, ActionWithPayload, ActionWithError, ActionWithPayloadCreator, ActionWithErrorCreator, ErrorSeverity } from "../../framework/actions";
import { LOAD_CURRENT_PLAYER, LOAD_CURRENT_PLAYER_SUCCESS, LOAD_CURRENT_PLAYER_FAILED, CREATE_PLAYER, CREATE_PLAYER_SUCCESS, CREATE_PLAYER_FAILED, UPDATE_PLAYER, UPDATE_PLAYER_SUCCESS, UPDATE_PLAYER_FAILED } from "./actionTypes";
import { Player } from "./models";
import { intersection } from "ramda";

export interface LoadCurrentPlayer extends ActionWithPayload<TokenAware> {
    type: typeof LOAD_CURRENT_PLAYER
}
export interface LoadCurrentPlayerSuccess extends ActionWithPayload<Player> {
    type: typeof LOAD_CURRENT_PLAYER_SUCCESS
}
export interface LoadCurrentPlayerFailed extends ActionWithError {
    type: typeof LOAD_CURRENT_PLAYER_FAILED
}
export interface CreatePlayer extends ActionWithPayload<Player & TokenAware> {
    type: typeof CREATE_PLAYER
}
export interface CreatePlayerSuccess extends ActionWithPayload<Player> {
    type: typeof CREATE_PLAYER_SUCCESS
}
export interface CreatePlayerFailed extends ActionWithError {
    type: typeof CREATE_PLAYER_FAILED
}
export interface UpdatePlayer extends ActionWithPayload<Player & TokenAware> {
    type: typeof UPDATE_PLAYER
}
export interface UpdatePlayerSuccess extends ActionWithPayload<Player> {
    type: typeof UPDATE_PLAYER_SUCCESS
}
export interface UpdatePlayerFailed extends ActionWithError {
    type: typeof UPDATE_PLAYER_FAILED
}

export const loadCurrentPlayer: ActionWithPayloadCreator<LoadCurrentPlayer, TokenAware> = (payload) => ({ type: "LOAD_CURRENT_PLAYER", payload });
export const loadCurrentPlayerSuccess: ActionWithPayloadCreator<LoadCurrentPlayerSuccess, Player> = (payload) => ({ type: "LOAD_CURRENT_PLAYER_SUCCESS", payload });
export const loadCurrentPlayerFailed: ActionWithErrorCreator<LoadCurrentPlayerFailed> = (error) => ({ type: "LOAD_CURRENT_PLAYER_FAILED", error, message: "", severity: ErrorSeverity.NONE });
export const createPlayer: ActionWithPayloadCreator<CreatePlayer, Player & TokenAware> = (payload) => ({ type: "CREATE_PLAYER", payload });
export const createPlayerSuccess: ActionWithPayloadCreator<CreatePlayerSuccess, Player> = (payload) => ({ type: "CREATE_PLAYER_SUCCESS", payload });
export const createPlayerFailed: ActionWithErrorCreator<CreatePlayerFailed> = (error) => ({ type: "CREATE_PLAYER_FAILED", error, message: "Nie udało nam się zapisać Twoich danych. Spróbuj wylogować się i zalogować ponownie.", severity: ErrorSeverity.ERROR });
export const updatePlayer: ActionWithPayloadCreator<UpdatePlayer, Player & TokenAware> = (payload) => ({ type: "UPDATE_PLAYER", payload });
export const updatePlayerSuccess: ActionWithPayloadCreator<UpdatePlayerSuccess, Player> = (payload) => ({ type: "UPDATE_PLAYER_SUCCESS", payload });
export const updatePlayerFailed: ActionWithErrorCreator<UpdatePlayerFailed> = (error) => ({ type: "UPDATE_PLAYER_FAILED", error, message: "Nie udało nam się zapisać Twoich danych. Spróbuj wylogować się i zalogować ponownie.", severity: ErrorSeverity.ERROR });

export type Actions =
    | LoadCurrentPlayer
    | LoadCurrentPlayerSuccess
    | LoadCurrentPlayerFailed
    | CreatePlayer
    | CreatePlayerSuccess
    | CreatePlayerFailed
    | UpdatePlayer
    | UpdatePlayerSuccess
    | UpdatePlayerFailed;