import { Data, NOT_STARTED, LOADING, dataOf, fail } from "../../framework/data";
import { PriceDetails, SignupStatus, SignupNotification } from "./models";
import { Actions } from "./actions";
import { combineReducers } from "redux";

export interface State {
    priceDetails: Data<PriceDetails>;
    status: SignupStatus;
    notifications: SignupNotification[];
}

function reducePriceDetails(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_TOURNAMENT_PRICING": return LOADING;
        case "LOAD_TOURNAMENT_PRICING_FAILED": return fail(action.message);
        case "LOAD_TOURNAMENT_PRICING_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

function reduceStatus(state = SignupStatus.NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "NOTIFY_SIGNUP_STATUS": return action.payload;
        case "START_TOURNAMENT_SIGNUP": return SignupStatus.IN_PROGRESS;
        default: return state;
    }
}

function reduceNotifications(state: SignupNotification[] = [], action: Actions) {
    switch(action.type) {
        case "ADD_SIGNUP_NOTIFICATION": return [...state, action.payload];
        default: return state;
    }
}

export default combineReducers({
    priceDetails: reducePriceDetails,
    status: reduceStatus,
    notifications: reduceNotifications
});
