import { takeEvery, call, put, take, delay } from "redux-saga/effects";
import { LOAD_TOURNAMENT_PRICING, START_TOURNAMENT_SIGNUP } from "./actionTypes";
import { LoadTournamentPricing, loadTournamentPricingSuccess, loadTournamentPricingFailed, StartTournamentSignup, addSignupNotification } from "./actions";
import { loadTournamentPricingApi } from "../tournaments/api";
import { UPDATE_PLAYER_SUCCESS, CREATE_PLAYER_SUCCESS } from "../players/actionTypes";
import { updatePlayer, createPlayer } from "../players/actions";
import { addPlayerToTournament } from "../tournaments/actions";
import { ADD_PLAYER_TO_TOURNAMENT_SUCCESS } from "../tournaments/actionTypes";

function* loadTournamentPricingStep({ payload }: LoadTournamentPricing) {
    try {
        const response = yield call(loadTournamentPricingApi, payload.province, payload.id, payload.promoCode);
        yield put(loadTournamentPricingSuccess(response));
    } catch (e) {
        yield put(loadTournamentPricingFailed(e.message));
    }
}

function* tournamentSignup({ payload }: StartTournamentSignup) {

    debugger;
    if (payload.player.id) {
        const response = yield put(updatePlayer({...payload.player, token: payload.token}));
        yield take(UPDATE_PLAYER_SUCCESS);
        yield put(addSignupNotification({ isError: false, message: "Profil zawodnika został zaktualizowany. Dodajemy zawodnika do turnieju..." }));
    } else {
        const response = yield put(createPlayer({...payload.player, token: payload.token }));
        yield take(CREATE_PLAYER_SUCCESS);
        yield put(addSignupNotification({ isError: false, message: "Profil zawodnika zostałutworzony. Dodajemy zawodnika do turnieju..." }))
    }

    yield put(addPlayerToTournament({ id: payload.tournamentId, province: payload.tournamentProvince, promoCode: payload.additionalModel.promo, referral: payload.additionalModel.referral, token: payload.token }));
    const addPlayerToTournamentSuccess = yield take(ADD_PLAYER_TO_TOURNAMENT_SUCCESS)
    yield put(addSignupNotification({ isError: false, message: "Zawodnik dodany do turnieju. Zaraz przeniesiemy Cię do płatności..." }));

    yield delay(5000);

    window.location.assign(addPlayerToTournamentSuccess.payload.paymentLink)

}

export function* loadTournamentPricingSaga() {
    yield takeEvery(LOAD_TOURNAMENT_PRICING, loadTournamentPricingStep);
}

export function* startTournamentSignupSaga() {
    yield takeEvery(START_TOURNAMENT_SIGNUP, tournamentSignup);
}