import { ActionWithError, WithNotification, Notification } from "../../framework/actions";
import { combineReducers, Action } from "redux";
import { CLEAR_CURRENT_ERROR, CLEAR_NOTIFICATION } from "./actionTypes";

export interface State {
    errorAction: ActionWithError;
    notification: Notification;
}

function reduceErrorAction(state = null, action: ActionWithError) {
    if(action.error) {
        return action;
    }
    if(action.type === CLEAR_CURRENT_ERROR) {
        return null;
    }
    return state;
}

function reduceNotification(state = null, action: WithNotification & Action) {
    if(action.notification) {
        return action.notification;
    }
    if(action.type === CLEAR_NOTIFICATION) {
        return null;
    }
    return state;

}

export default combineReducers({ errorAction: reduceErrorAction, notification: reduceNotification });