import React from "react";
import {
  Typography,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { SignupStatus, SignupNotification, AdditionalSignupModel, TournamentSignupPayload } from "../../store/signup/models";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { startTournamentSignup } from "../../store/signup/actions";
import { connect } from "react-redux";
import { Player } from "../../store/players/models";
import { Tournament } from "../../store/tournaments/models";
import { useAuth0 } from "../shell/Auth0Context";

interface SignupLogsProps {
  status: SignupStatus;
  notifications: SignupNotification[];
  startTournamentSignup: (p: TournamentSignupPayload) => void;

  player: Player;
  model: AdditionalSignupModel;
  tournament: Tournament;
}

const processIcon = (status: SignupStatus) => {
  switch (status) {
    case SignupStatus.NOT_STARTED:
    case SignupStatus.IN_PROGRESS: {
      return <CircularProgress />;
    }
    case SignupStatus.FINISHED: {
      return <CheckCircleOutlineIcon color="primary" fontSize="large" />;
    }
    case SignupStatus.FAILED: {
      return <ErrorOutlineIcon color="error" fontSize="large" />;
    }
    default:
      return <></>;
  }
};

const itemIcon = (isError: boolean) =>
  isError ? (
    <ErrorOutlineIcon color="error" />
  ) : (
    <CheckCircleOutlineIcon color="primary" />
  );

const SignupLogs: React.FC<SignupLogsProps> = ({ status, notifications, player, model, tournament, startTournamentSignup }) => {

    const { getTokenSilently } = useAuth0();

    React.useEffect(() => {
        if(status !== SignupStatus.IN_PROGRESS) {
            getTokenSilently().then((t: string) => {
                startTournamentSignup({
                    additionalModel: model,
                    player,
                    tournamentId: tournament.id,
                    tournamentProvince: tournament.province,
                    token: t
                });
            })
        }
    }, [])

  return (
    <>
      <Typography variant="h4">Już prawie gotowe...</Typography>
      <Grid container direction="column" justify="center" alignItems="center" spacing={4}>
        <Grid item>{processIcon(status)}</Grid>
        <Grid item>
          <List dense={true}>
            {notifications.map((n, i) => (
              <ListItem key={i}>
                <ListItemIcon>{itemIcon(n.isError)}</ListItemIcon>
                <ListItemText primary={n.message}></ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  status: state.signup.status,
  notifications: state.signup.notifications,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  startTournamentSignup: (p: TournamentSignupPayload) => dispatch(startTournamentSignup(p)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupLogs);
