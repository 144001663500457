import { getConfig } from "../../config/configuration";
import { TournamentHeader, TournamentHeaderResponse, Tournament, TournamentResponse, TournamentSubscriptionStatus } from "./models";
import { PriceDetails } from "../signup/models";

export async function loadTournamentsApi(province: number): Promise<TournamentHeader[]> {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/tournaments/${province}`);

    if(response.ok) {
        const responseItems: TournamentHeaderResponse[] = await response.json();
        return responseItems.map(x => ({ ...x, date: new Date(x.date) }));
    } else {
        throw new Error(response.statusText);
    }
}

export async function loadMyTournamentsApi(province: number, token: string): Promise<TournamentSubscriptionStatus[]> {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/tournaments/${province}/subscribed`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    if(response.ok) {
        const ids: TournamentSubscriptionStatus[] = await response.json();
        return ids;
    } else {
        throw new Error(response.statusText);
    }
}

export async function loadTournamentApi(province: number, id: string): Promise<Tournament> {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/tournaments/${province}/${id}`);

    if(response.ok) {
        const tournamentResponse: TournamentResponse = await response.json();
        return { ...tournamentResponse, date: new Date(tournamentResponse.date) };
    } else {
        throw new Error(response.statusText);
    }
}

export async function loadTournamentPricingApi(province: number, id: string, promo: string): Promise<PriceDetails> {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/tournaments/${province}/${id}/price?promoCode=${promo}`);

    if(response.ok) {
        return await response.json();
    } else {
        throw new Error(response.statusText);
    }
}

export async function addPlayerToTournamentApi(id: string, province: number, promoCode: string, referral: string, token: string) {
    const config = await getConfig();

    const response = await fetch(`${config.apiUrl}/tournaments/${province}/${id}/players`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            promoCode,
            referral
        })
    });

    if(response.ok) {
        return await response.json();
    } else {
        throw new Error(response.statusText);
    }
}