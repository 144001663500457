import { ActionWithPayload, IdAware, ProvinceAware, ActionWithError, ActionWithPayloadCreator, ActionWithErrorCreator, ErrorSeverity } from "../../framework/actions";
import { LOAD_TOURNAMENT_PRICING, LOAD_TOURNAMENT_PRICING_SUCCESS, LOAD_TOURNAMENT_PRICING_FAILED, NOTIFY_SIGNUP_STATUS, ADD_SIGNUP_NOTIFICATION, START_TOURNAMENT_SIGNUP } from "./actionTypes";
import { PriceDetails, SignupStatus, SignupNotification, TournamentSignupPayload } from "./models";

export interface PromoCodeAware {
    promoCode: string;
}

export interface LoadTournamentPricing extends ActionWithPayload<ProvinceAware & IdAware & PromoCodeAware> {
    type: typeof LOAD_TOURNAMENT_PRICING
}

export interface LoadTournamentPricingSuccess extends ActionWithPayload<PriceDetails> {
    type: typeof LOAD_TOURNAMENT_PRICING_SUCCESS
}

export interface LoadTournamentPricingFailed extends ActionWithError {
    type: typeof LOAD_TOURNAMENT_PRICING_FAILED
}

export interface NotifySignupStatus extends ActionWithPayload<SignupStatus> {
    type: typeof NOTIFY_SIGNUP_STATUS
}

export interface AddSignupNotification extends ActionWithPayload<SignupNotification> {
    type: typeof ADD_SIGNUP_NOTIFICATION
}

export interface StartTournamentSignup extends ActionWithPayload<TournamentSignupPayload> {
    type: typeof START_TOURNAMENT_SIGNUP
}

export const loadTournamentPricing: ActionWithPayloadCreator<LoadTournamentPricing, ProvinceAware & IdAware & PromoCodeAware> = (payload) => ({ type: "LOAD_TOURNAMENT_PRICING", payload });
export const loadTournamentPricingSuccess: ActionWithPayloadCreator<LoadTournamentPricingSuccess, PriceDetails> = (payload) => ({ type: "LOAD_TOURNAMENT_PRICING_SUCCESS", payload });
export const loadTournamentPricingFailed: ActionWithErrorCreator<LoadTournamentPricingFailed> = (error) => ({ type: "LOAD_TOURNAMENT_PRICING_FAILED", error, message: "Nie udało nam się wyliczyć ceny dołączenia do turnieju. Kliknij 'Odśwież', aby spróbować ponownie.", severity: ErrorSeverity.WARNING });

export const notifySignupStatus: ActionWithPayloadCreator<NotifySignupStatus, SignupStatus> = (payload) => ({ type: "NOTIFY_SIGNUP_STATUS", payload });
export const addSignupNotification: ActionWithPayloadCreator<AddSignupNotification, SignupNotification> = (payload) => ({ type: "ADD_SIGNUP_NOTIFICATION", payload });
export const startTournamentSignup: ActionWithPayloadCreator<StartTournamentSignup, TournamentSignupPayload> = (payload) => ({ type: "START_TOURNAMENT_SIGNUP", payload });

export type Actions = 
    | LoadTournamentPricing
    | LoadTournamentPricingSuccess
    | LoadTournamentPricingFailed
    | NotifySignupStatus
    | AddSignupNotification
    | StartTournamentSignup;