import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

export default () => (
  <Grid container alignItems="center" justify="center">
      <Grid item>
        <CircularProgress></CircularProgress>
      </Grid>
  </Grid>
);
