import { takeEvery, call, put, delay } from "redux-saga/effects";
import { GET_USER_PAYMENTS, REDO_USER_PAYMENT } from "./actionTypes";
import { GetUserPayments, getUserPaymentsSuccess, getUserPaymentsFailed, getUserPayments, RedoUserPayment, redoUserPaymentFailed } from "./actions";
import { PaymentInfo, PaymentStatus, PaymentOrder } from "./models";
import { loadPlayersPaymentsApi, redoUserPaymentApi} from "./api";

export function* getUserPaymentsStep({ payload }: GetUserPayments) {
    const token = payload;

    try {

        const result: PaymentInfo[] = yield call(loadPlayersPaymentsApi, token);
        yield put(getUserPaymentsSuccess(result));

        if(!!result.find(x => x.paymentStatus === PaymentStatus.PENDING || x.paymentStatus === PaymentStatus.WAITING_FOR_CONFIRMATION)) {
            yield delay(10000);
            yield put(getUserPayments(token));
        }
    } catch(e) {
        yield put(getUserPaymentsFailed(e.message));
    }
}

export function* redoUserPaymentStep({ payload }: RedoUserPayment) {
    
    const { token, payment } = payload;
debugger;
    try {
        const paymentNeeded: PaymentOrder = yield call(redoUserPaymentApi, token, payment.externalPaymentOrderId);
        if(paymentNeeded && paymentNeeded.redirectUri) {
            window.location.assign(paymentNeeded.redirectUri);
        }
        //yield put(getUserPayments(token));
    } catch (e) {
        yield put(redoUserPaymentFailed(e.message));
    }

}

export function* getUserPaymentsSaga() {
    yield takeEvery(GET_USER_PAYMENTS, getUserPaymentsStep);
}

export function* redoUserPaymentSaga() {
    yield takeEvery(REDO_USER_PAYMENT, redoUserPaymentStep);
}