export const LOAD_CURRENT_PLAYER = "LOAD_CURRENT_PLAYER";
export const LOAD_CURRENT_PLAYER_SUCCESS = "LOAD_CURRENT_PLAYER_SUCCESS";
export const LOAD_CURRENT_PLAYER_FAILED = "LOAD_CURRENT_PLAYER_FAILED";

export const CREATE_PLAYER = "CREATE_PLAYER";
export const CREATE_PLAYER_SUCCESS = "CREATE_PLAYER_SUCCESS";
export const CREATE_PLAYER_FAILED = "CREATE_PLAYER_FAILED";

export const UPDATE_PLAYER = "UPDATE_PLAYER";
export const UPDATE_PLAYER_SUCCESS = "UPDATE_PLAYER_SUCCESS";
export const UPDATE_PLAYER_FAILED = "UPDATE_PLAYER_FAILED";