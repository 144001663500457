import { ClubDescriptor } from "../clubs/models";
import { PlayerType } from "../common/models";
import { IdAware, TokenAware, ProvinceAware } from "../../framework/actions";

export interface TournamentHeaderResponse {
    id: string;
    name: string;
    club: ClubDescriptor;
    province: number;
    date: string;
    description: string;
    playerType: PlayerType;
    pricesDescription: string;
    status: TournamentStatus;
    activePlayers: number;
}

export interface TournamentResponse {
    id: string;
    name: string;
    club: ClubDescriptor;
    province: number;
    date: string;
    description: string;
    playerType: PlayerType;
    pricesDescription: string;
    status: TournamentStatus;
    players: TournamentPlayer[];
    reserveList: TournamentPlayer[];
    spreadsheetUrl: string;
    currentView: TournamentView;
}

export enum TournamentView {
    PlayerList,
    Spreadsheet
}

export interface TournamentPlayer {
    rank: number;
    name: string;
    score: TournamentPlayerScore;
}

export interface TournamentPlayerScore {
    matchesPlayed: number;
    matchesWon: number;
    setsPlayed: number;
    setsWon: number;
    gemsWon: number;
    gemsLost: number;
    points: number;
}

export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;
export type Extend<T, V> = T & V;

export type TournamentHeader = Overwrite<TournamentHeaderResponse, { date: Date }>;

export type Tournament = Overwrite<TournamentResponse, {date: Date}>;

export interface TournamentSubscriptionStatus {
    tournamentId: string;
    unifinishedPaymentExists: boolean;
}

export type TournamentHeaderWithUserAssignment = Extend<TournamentHeader, { userAssigned: boolean }>;

export type TournamentWithUserAssigned = Extend<Tournament, { userAssigned: boolean}>;

export enum TournamentStatus {
    DRAFT = 0, 
    NEW = 1, 
    ACCEPT_SUBMISSIONS = 2, 
    IN_PPROGRESS = 3, 
    FINISHED = 4
}


export type TournamentSignupModel = {
    promoCode: string;
    referral: string;
} & IdAware & TokenAware & ProvinceAware;

export const getProvince = (n: number) => {
    switch(n) {
        case 0: return "dolnośląskie";
        case 1: return "kujawsko-pomorskie";
        case 2: return "lubelskie";
        case 3: return "lubuskie";
        case 4: return "łódzkie";
        case 5: return "małopolskie";
        case 6: return "mazowieckie";
        case 7: return "opolskie";
        case 8: return "podkarpackie";
        case 9: return "podlaskie";
        case 10: return "pomorskie";
        case 11: return "ślaskie";
        case 12: return "świętokrzyskie";
        case 13: return "warmińsko-mazurskie";
        case 14: return "wielkopolskie";
        case 15: return "zachodniopomorskie";
        default: return "";
    }
}