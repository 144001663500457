import { TournamentHeader, Tournament, TournamentSignupModel, TournamentSubscriptionStatus } from "./models";
import { ActionWithPayload, ActionWithError, ActionWithPayloadCreator, ActionWithErrorCreator, ErrorSeverity, TokenAware, ProvinceAware, IdAware } from "../../framework/actions";
import { LOAD_TOURNAMENTS, LOAD_TOURNAMENTS_SUCCESS, LOAD_TOURNAMENTS_FAILED, LOAD_MY_TOURNAMENTS, LOAD_MY_TOURNAMENTS_SUCCESS, LOAD_MY_TOURNAMENTS_FAILED, LOAD_CURRENT_TOURNAMENT, LOAD_CURRENT_TOURNAMENT_SUCCESS, LOAD_CURRENT_TOURNAMENT_FAILED, ADD_PLAYER_TO_TOURNAMENT, ADD_PLAYER_TO_TOURNAMENT_SUCCESS, ADD_PLAYER_TO_TOURNAMENT_FAILED } from "./actionTypes";
import { TournamentSignupPayload } from "../signup/models";

export interface LoadTournaments extends ActionWithPayload<ProvinceAware> {
    type: typeof LOAD_TOURNAMENTS
}
export interface LoadTournamentsSuccess extends ActionWithPayload<TournamentHeader[]> {
    type: typeof LOAD_TOURNAMENTS_SUCCESS
}
export interface LoadTournamentsFailed extends ActionWithError {
    type: typeof LOAD_TOURNAMENTS_FAILED
}
export interface LoadMyTournaments extends ActionWithPayload<ProvinceAware & TokenAware> {
    type: typeof LOAD_MY_TOURNAMENTS
}
export interface LoadMyTournamentsSuccess extends ActionWithPayload<TournamentSubscriptionStatus[]> {
    type: typeof LOAD_MY_TOURNAMENTS_SUCCESS
}
export interface LoadMyTournamentsFailed extends ActionWithError {
    type: typeof LOAD_MY_TOURNAMENTS_FAILED
}
export interface LoadCurrentTournament extends ActionWithPayload<ProvinceAware & IdAware> {
    type: typeof LOAD_CURRENT_TOURNAMENT
}
export interface LoadCurrentTournamentSuccess extends ActionWithPayload<Tournament> {
    type: typeof LOAD_CURRENT_TOURNAMENT_SUCCESS
}
export interface LoadCurrentTournamentFailed extends ActionWithError {
    type: typeof LOAD_CURRENT_TOURNAMENT_FAILED
}
export interface AddPlayerToTournament extends ActionWithPayload<TournamentSignupModel> {
    type: typeof ADD_PLAYER_TO_TOURNAMENT
}
export interface AddPlayerToTournamentSuccess extends ActionWithPayload<any> {
    type: typeof ADD_PLAYER_TO_TOURNAMENT_SUCCESS
}
export interface AddPlayerToTournamentFailed extends ActionWithError {
    type: typeof ADD_PLAYER_TO_TOURNAMENT_FAILED
}

export const loadTournaments: ActionWithPayloadCreator<LoadTournaments, ProvinceAware> = (payload: ProvinceAware) => ({ type: "LOAD_TOURNAMENTS", payload });
export const loadTournamentsSuccess: ActionWithPayloadCreator<LoadTournamentsSuccess, TournamentHeader[]> = (payload: TournamentHeader[]) => ({ type: "LOAD_TOURNAMENTS_SUCCESS", payload });
export const loadTournamentsFailed: ActionWithErrorCreator<LoadTournamentsFailed> = (error: string) => ({ type: "LOAD_TOURNAMENTS_FAILED", severity: ErrorSeverity.ERROR, message: "Nie udało nam się załadować aktywnych turniejów. Spróbuj ponownie, bądź przeładuj stronę.", error });

export const loadMyTournaments: ActionWithPayloadCreator<LoadMyTournaments, ProvinceAware & TokenAware> = (payload) => ({ type: "LOAD_MY_TOURNAMENTS", payload });
export const loadMyTournamentsSuccess: ActionWithPayloadCreator<LoadMyTournamentsSuccess, TournamentSubscriptionStatus[]> = (payload: TournamentSubscriptionStatus[]) => ({ type: "LOAD_MY_TOURNAMENTS_SUCCESS", payload });
export const loadMyTournamentsFailed: ActionWithErrorCreator<LoadMyTournamentsFailed> = (error) => ({ type: "LOAD_MY_TOURNAMENTS_FAILED", error, message: "Nie udało nam się pobrać informacji o turniejach, do których jesteś zapisany/-a", severity: ErrorSeverity.WARNING });

export const loadCurrentTournament: ActionWithPayloadCreator<LoadCurrentTournament, ProvinceAware & IdAware> = (payload) => ({ type: "LOAD_CURRENT_TOURNAMENT", payload });
export const loadCurrentTournamentSuccess: ActionWithPayloadCreator<LoadCurrentTournamentSuccess, Tournament> = (payload) => ({ type: "LOAD_CURRENT_TOURNAMENT_SUCCESS", payload });
export const loadCurrentTournamentFailed: ActionWithErrorCreator<LoadCurrentTournamentFailed> = (error) => ({ type: "LOAD_CURRENT_TOURNAMENT_FAILED", error, message: "Nie udało nam się załadować turnieju. Przeładuj stronę i spróbuj ponownie.", severity: ErrorSeverity.ERROR });

export const addPlayerToTournament: ActionWithPayloadCreator<AddPlayerToTournament, TournamentSignupModel> = (payload) => ({ type: "ADD_PLAYER_TO_TOURNAMENT", payload });
export const addPlayerToTournamentSuccess: ActionWithPayloadCreator<AddPlayerToTournamentSuccess, any> = (payload) => ({ type: "ADD_PLAYER_TO_TOURNAMENT_SUCCESS", payload });
export const addPlayerToTournamentFailed: ActionWithErrorCreator<AddPlayerToTournamentFailed> = (error) => ({ type: "ADD_PLAYER_TO_TOURNAMENT_FAILED", error,  message: "Nie udało nam się zapisać Cię na turniej. Przeładuj stronę i spróbuj ponownie.", severity: ErrorSeverity.ERROR });

export type Actions =
    | LoadTournaments
    | LoadTournamentsSuccess
    | LoadTournamentsFailed
    | LoadMyTournaments
    | LoadMyTournamentsSuccess
    | LoadMyTournamentsFailed
    | LoadCurrentTournament
    | LoadCurrentTournamentSuccess
    | LoadCurrentTournamentFailed
    | AddPlayerToTournament
    | AddPlayerToTournamentSuccess
    | AddPlayerToTournamentFailed;

