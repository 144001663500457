import React from "react";
import { TournamentPlayer } from "../../store/tournaments/models";
import { take, drop } from "ramda";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@material-ui/core";

interface TournamentReserveListProps {
  players: TournamentPlayer[];
}

const TournamentReserveList: React.FC<TournamentReserveListProps> = ({
  players,
}) => {

  const [page, setPage] = React.useState<number>();
  const [pageSize, setPageSize] = React.useState<number>();

  const viewItems = take(
    pageSize || 25,
    drop((pageSize || 25) * (page || 0), players)
  );

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">#</TableCell>
              <TableCell>Zawodnik</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewItems.map((player, i) => (
              <TableRow key={i} style={{ height: "61px" }}>
                <TableCell align="right">
                  <span>{i + 1 + (page || 0) * (pageSize || 25)}</span>
                </TableCell>
                <TableCell>
                  <span>{player.name}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={players.length}
        rowsPerPage={pageSize ?? 25}
        page={page ?? 0}
        onChangePage={(e, p: number) => {
          setPage(p);
        }}
        onChangeRowsPerPage={(e) => {
          setPageSize(parseInt(e.target.value));
        }}
      />
    </>
  );
};

export default TournamentReserveList;
