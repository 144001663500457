import React from "react";
import {  getProvince, TournamentHeaderWithUserAssignment } from "../../store/tournaments/models";
import TournamentHeader from './TournamentHeader';
import { Typography } from "@material-ui/core";
import ExploreIcon from '@material-ui/icons/Explore';
import {  sortBy } from "ramda";

export interface TournamentsGroupProps {
  province: number;
  tournaments: TournamentHeaderWithUserAssignment[];
}

export const TournamentsGroup: React.FC<TournamentsGroupProps> = ({ province, tournaments }) => (
  <div style={{ marginBottom: "16px" }}>
    <Typography
      color={"primary"}
      variant="h5"
      style={{ marginBottom: "8px" }}
    >
      <ExploreIcon fontSize={"small"} />Woj. {getProvince(province)}
    </Typography>
    <div>
        {
            sortBy(x => x.date, tournaments).map(t => (
                <TournamentHeader tournament={t} key={t.id}></TournamentHeader>
            ))
        }
    </div>
  </div>
);
