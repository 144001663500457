import { LOAD_TOURNAMENTS, LOAD_MY_TOURNAMENTS, LOAD_CURRENT_TOURNAMENT, ADD_PLAYER_TO_TOURNAMENT } from "./actionTypes";
import { takeEvery, put, call } from "redux-saga/effects";
import { LoadTournaments, loadTournamentsFailed, loadTournamentsSuccess, LoadMyTournaments, loadMyTournamentsSuccess, loadMyTournamentsFailed, LoadCurrentTournament, loadCurrentTournamentSuccess, loadCurrentTournamentFailed, AddPlayerToTournament, addPlayerToTournamentSuccess, addPlayerToTournamentFailed } from "./actions";
import { loadTournamentsApi, loadMyTournamentsApi, loadTournamentApi, addPlayerToTournamentApi } from "./api";


function* loadTournamentsStep({ payload }: LoadTournaments) {
    try {
        const response = yield call(loadTournamentsApi, payload.province);
        yield put(loadTournamentsSuccess(response));
    } catch(e) {
        yield put(loadTournamentsFailed(e.message));
    }
}

function* loadMyTournamentsStep({ payload }: LoadMyTournaments) {
    try {
        const response = yield call(loadMyTournamentsApi, payload.province, payload.token);
        yield put(loadMyTournamentsSuccess(response));
    } catch(e) {
        yield put(loadMyTournamentsFailed(e.message));
    }
}

function* loadCurrentTournamentStep({ payload }: LoadCurrentTournament) {
    try {
        const response = yield call(loadTournamentApi, payload.province, payload.id);
        yield put(loadCurrentTournamentSuccess(response));
    } catch(e) {
        yield put(loadCurrentTournamentFailed(e.message));
    }
}

function* addPlayerToTournamentStep({ payload }: AddPlayerToTournament) {
    try {
        const response = yield call(addPlayerToTournamentApi, payload.id, payload.province, payload.promoCode, payload.referral, payload.token);
        yield put(addPlayerToTournamentSuccess(response));
    } catch(e) {
        yield put(addPlayerToTournamentFailed(e.message));
    }
}

export function* loadTournamentsSaga() {
    yield takeEvery(LOAD_TOURNAMENTS, loadTournamentsStep);
}

export function* loadMyTournamentsSaga() {
    yield takeEvery(LOAD_MY_TOURNAMENTS, loadMyTournamentsStep);
}

export function* loadCurrentTournamentSaga() {
    yield takeEvery(LOAD_CURRENT_TOURNAMENT, loadCurrentTournamentStep);
}

export function* addPlayerToTournamentSaga() {
    yield takeEvery(ADD_PLAYER_TO_TOURNAMENT, addPlayerToTournamentStep)
}