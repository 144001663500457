import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import AccountMenu from "./AccountMenu";
import { Grid } from "@material-ui/core";
import Notifier from "./Notifier";

const TopBar: React.FC = () => {
  return (
    <AppBar color="inherit" position="static" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center" onClick={() => window.location.assign("/")} style={{ cursor: "pointer", width: "initial" }}>
          <Grid item>
          <img src="logo.png" style={{ height: '48px', marginTop: '4px' }} />
          </Grid>
        </Grid>

        <div style={{ flexGrow: 1 }}></div>
        <Notifier/>
        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
