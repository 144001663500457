export const LOAD_TOURNAMENTS = "LOAD_TOURNAMENTS";
export const LOAD_TOURNAMENTS_SUCCESS = "LOAD_TOURNAMENTS_SUCCESS";
export const LOAD_TOURNAMENTS_FAILED = "LOAD_TOURNAMENTS_FAILED";

export const LOAD_MY_TOURNAMENTS = "LOAD_MY_TOURNAMENTS";
export const LOAD_MY_TOURNAMENTS_SUCCESS = "LOAD_MY_TOURNAMENTS_SUCCESS";
export const LOAD_MY_TOURNAMENTS_FAILED = "LOAD_MY_TOURNAMENTS_FAILED";

export const LOAD_CURRENT_TOURNAMENT = "LOAD_CURRENT_TOURNAMENT";
export const LOAD_CURRENT_TOURNAMENT_SUCCESS = "LOAD_CURRENT_TOURNAMENT_SUCCESS";
export const LOAD_CURRENT_TOURNAMENT_FAILED = "LOAD_CURRENT_TOURNAMENT_FAILED";

export const ADD_PLAYER_TO_TOURNAMENT = "ADD_PLAYER_TO_TOURNAMENT";
export const ADD_PLAYER_TO_TOURNAMENT_SUCCESS = "ADD_PLAYER_TO_TOURNAMENT_SUCCESS";
export const ADD_PLAYER_TO_TOURNAMENT_FAILED = "ADD_PLAYER_TO_TOURNAMENT_FAILED";