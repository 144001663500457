import { createSelector } from 'reselect';

import { AppState } from "../reducer";
import { TournamentHeaderWithUserAssignment, TournamentWithUserAssigned } from './models';
import { dataOf } from '../../framework/data';

export const tournaments = (state: AppState) => state.tournaments.items;
export const currentTournament = (state: AppState) => state.tournaments.current;
export const mySubscriptions = (state: AppState) => state.tournaments.subscribedTo;

export const tournamentsWithCurrentUser = createSelector(tournaments, mySubscriptions, (tournamentsData, subscribedTo) => {

    if(tournamentsData.type === "LOADED") {

        const tournaments = tournamentsData.value;
        return  dataOf(tournaments.map(x => <TournamentHeaderWithUserAssignment>{ ...x, userAssigned: !!subscribedTo.find(e => e.tournamentId === x.id) }));

    } 

    return tournamentsData;

});

export const tournamentwithCurrentUser = createSelector(currentTournament, mySubscriptions, (tournamentData, subscribedTo) => {

    if(tournamentData.type === "LOADED") {
        const tournament = tournamentData.value;

        return dataOf<TournamentWithUserAssigned>({ ...tournament, userAssigned: !!subscribedTo.find(e => e.tournamentId === tournament.id) });
    }

    return tournamentData;

})