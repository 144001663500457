import { Data, NOT_STARTED, LOADING, dataOf } from "../../framework/data";
import { Player } from "./models";
import { Actions } from "./actions";
import { combineReducers } from "redux";

export interface State {

    current: Data<Player>;

}

function reduceCurrent(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_CURRENT_PLAYER": return LOADING;
        case "LOAD_CURRENT_PLAYER_SUCCESS": return dataOf(action.payload);
        case "LOAD_CURRENT_PLAYER_FAILED": return dataOf(undefined);
        default: return state;
    }
}

export default combineReducers({
    current: reduceCurrent
});