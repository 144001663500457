import React from "react";
import { TournamentWithUserAssigned, TournamentView } from "../../store/tournaments/models";
import { Typography, Grid, Button } from "@material-ui/core";
import TournamentPlayersStats from "./TournamentPlayersStats";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ReactMarkdown from "react-markdown";
import TournamentReserveList from "./TournamentReserveList";

interface TournamentDetailsProps {
  tournament: TournamentWithUserAssigned;
}

const TournamentDetails: React.FC<TournamentDetailsProps> = ({
  tournament,
}) => {
  return (
    <>
      <Typography variant="h4" color="secondary">
        {tournament.name}
      </Typography>
      <div style={{ padding: "32px 0" }}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="body1">
              Klub, na obiektach którego odbywa się dany turniej:{" "}
              <b>{tournament.club.name}</b>
            </Typography>
          </Grid>
          <Grid>
            <a href={tournament.club.websiteUrl} target="_blank">
              <img
                src={tournament.club.logoUrl}
                style={{ height: "64px" }}
                alt={tournament.club.name}
                title={tournament.club.name}
              />
            </a>
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: "32px 0" }}>
        <Typography variant="h5" color="primary">
          Statystyki
        </Typography>
        {
          tournament.currentView === TournamentView.PlayerList ? (
            <TournamentPlayersStats
          players={tournament.players}
        ></TournamentPlayersStats>
          ) : (
            <iframe src={tournament.spreadsheetUrl || "about:blank"} style={{width: "100%", height: (tournament.players || []).length * 100, border: 0, margin: '1px', padding: '1px'}} frameBorder={0}>
            </iframe>
          )
        }
        
      </div>
      <div style={{ padding: "32px 0" }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" color="primary">
              Opis
            </Typography>
            <ReactMarkdown source={tournament.description}></ReactMarkdown>
            <Typography variant="h5" color="primary">
              Nagrody
            </Typography>
            <ReactMarkdown
              source={tournament.pricesDescription}
            ></ReactMarkdown>
          </Grid>
          {tournament.currentView === TournamentView.PlayerList ? (
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" color="primary">
                Lista rezerwowa
              </Typography>
              <TournamentReserveList
                players={tournament.reserveList}
              ></TournamentReserveList>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </div>
      {tournament.userAssigned ? (
        <></>
      ) : (
        <Button
          color={"primary"}
          href={`#/tournaments/${tournament.province}/${tournament.id}/sign-up`}
        >
          <AssignmentIcon />
          Zapisz się
        </Button>
      )}
    </>
  );
};

export default TournamentDetails;
