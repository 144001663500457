import rootReducer from "./reducer";
import logger from '../framework/store-logger';
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga';
import { all, spawn, call } from 'redux-saga/effects'
import { loadTournamentsSaga, loadMyTournamentsSaga, loadCurrentTournamentSaga, addPlayerToTournamentSaga } from "./tournaments/sagas";
import { loadCurrentPlayerSaga, createPlayerSaga, updatePlayerSaga } from "./players/sagas";
import { loadTournamentPricingSaga, startTournamentSignupSaga } from "./signup/sagas";
import { getUserPaymentsSaga, redoUserPaymentSaga } from "./payments/sagas";

const sagaMiddleware = createSagaMiddleware()

export default createStore(rootReducer, applyMiddleware(logger, sagaMiddleware));

function* rootSaga() {
  const sagas = [loadTournamentsSaga, loadMyTournamentsSaga, loadCurrentTournamentSaga, loadCurrentPlayerSaga, 
    loadTournamentPricingSaga, startTournamentSignupSaga, createPlayerSaga, updatePlayerSaga, 
    addPlayerToTournamentSaga, getUserPaymentsSaga, redoUserPaymentSaga];

  yield all(sagas.map(saga =>
    spawn(function* () {
      while (true) {
        try {
          yield call(saga);
          break;
        } catch (e) {
          console.log(e);
        }
      }
    }))
  );
}

sagaMiddleware.run(rootSaga);