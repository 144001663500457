import React from "react";
import { Data } from "../../framework/data";
import { PriceDetails } from "../../store/signup/models";
import { Tournament } from "../../store/tournaments/models";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { loadTournamentPricing } from "../../store/signup/actions";
import { connect } from "react-redux";
import {
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import Loading from "../../framework/components/Loading";

interface SubmissionPaymentDetailsProps {
  promoCode: string;
  tournament: Tournament;
  loadPrice: (province: number, id: string, promoCode: string) => void;
  priceDetails: Data<PriceDetails>;
}

const SubmissionPaymentDetails: React.FC<SubmissionPaymentDetailsProps> = ({
  promoCode,
  tournament,
  priceDetails,
  loadPrice,
}) => {
  React.useEffect(() => {
    loadPrice(tournament.province, tournament.id, promoCode);
  }, [promoCode, tournament]);

  return (
    <>
      {" "}
      {priceDetails.type === "LOADED" ? (
        <div>
          <Alert severity="info" variant="outlined">
            W następnym kroku zostaniesz przeniesiony do systemu płatności PayU
          </Alert>

          <Typography variant="h6" style={{ marginTop: "16px" }}>
            {priceDetails.value.tournamentName}
          </Typography>

          <Grid
            container
            alignItems="baseline"
            spacing={2}
            style={{ marginTop: "8px" }}
          >
            <Grid item>
              <Typography variant="h4">
                {priceDetails.value.calculatedPrice} PLN
              </Typography>
            </Grid>
            {priceDetails.value.basePrice !==
            priceDetails.value.calculatedPrice ? (
              <Grid item>
                <Typography variant="body2">
                  Cena podstawowa: {priceDetails.value.basePrice} PLN
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          {!!priceDetails.value.additionalInfo ? (
            <Alert severity="error">{priceDetails.value.additionalInfo}. Wciśnij 'Wstecz' aby poprawić.</Alert>
          ) : (
            <></>
          )}
          {!!priceDetails.value.discounts &&
          priceDetails.value.discounts.length > 0 ? (
            <List
              subheader={
                <ListSubheader>Zastosowano następujące zniżki</ListSubheader>
              }
              style={{ backgroundColor: "#e8e8e8", marginTop: "8px" }}
            >
              {priceDetails.value.discounts.map((d, i) => (
                <ListItem key={i}>
                  <ListItemIcon>
                    <MoneyOffIcon></MoneyOffIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={`Wprowadzono kod promocyjny: ${d.discountName.toUpperCase()}`}
                  />
                  <ListItemSecondaryAction>
                    - {parseFloat(d.discount) * 100} %
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Loading></Loading>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  priceDetails: state.signup.priceDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadPrice: (province: number, id: string, promoCode: string) =>
    dispatch(loadTournamentPricing({ promoCode, province, id })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionPaymentDetails);
