import { Data, NOT_STARTED, LOADING, fail, dataOf } from "../../framework/data";
import { TournamentHeader, Tournament, TournamentSubscriptionStatus } from "./models";
import { Actions } from "./actions";
import { combineReducers } from "redux";

export interface State {

    items: Data<TournamentHeader[]>;
    current: Data<Tournament>;
    subscribedTo: TournamentSubscriptionStatus[];
}

function reduceItems(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_TOURNAMENTS": return LOADING;
        case "LOAD_TOURNAMENTS_FAILED": return fail(action.message);
        case "LOAD_TOURNAMENTS_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

const subscribedToInitialState: TournamentSubscriptionStatus[] = [];

function reduceSubscribedTo(state = subscribedToInitialState, action: Actions) {
    switch(action.type) {
        case "LOAD_MY_TOURNAMENTS": return state;
        case "LOAD_MY_TOURNAMENTS_SUCCESS": return action.payload;
        case "LOAD_MY_TOURNAMENTS_FAILED": return subscribedToInitialState;  
        default: return state;
    }
}

function reduceCurrent(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_CURRENT_TOURNAMENT": return LOADING;
        case "LOAD_CURRENT_TOURNAMENT_SUCCESS": return dataOf(action.payload);
        case "LOAD_CURRENT_TOURNAMENT_FAILED": return fail(action.message);
        default: return state;
    }
}

export default combineReducers({
    items: reduceItems,
    subscribedTo: reduceSubscribedTo,
    current: reduceCurrent
});
