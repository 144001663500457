import React from "react";
import { TournamentPlayer } from "../../store/tournaments/models";
import { Player } from "../../store/players/models";
import { sortBy, compose, toLower, prop, take, drop } from "ramda";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";

interface TournamentPlayersStatsProps {
  players: TournamentPlayer[];
}

const TournamentPlayersStats: React.FC<TournamentPlayersStatsProps> = ({
  players
}) => {
  const rankedPlayers = players
    .filter((p) => p.rank > 0)
    .sort((x, y) => x.rank - y.rank);
  const unrankedPlayers = sortBy(
    compose(toLower, prop("name")),
    players.filter((p) => p.rank === 0)
  );

  const orderedPlayers = [...rankedPlayers, ...unrankedPlayers];

  const [page, setPage] = React.useState<number>();
  const [pageSize, setPageSize] = React.useState<number>();

  const viewItems = take(
    pageSize || 25,
    drop((pageSize || 25) * (page || 0), orderedPlayers)
  );

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">#</TableCell>
              <TableCell>Zawodnik</TableCell>
              <TableCell align="center">Punkty</TableCell>
              <TableCell align="center">Mecze</TableCell>
              <TableCell align="center">M. Wygrane</TableCell>
              <TableCell align="center">Sety</TableCell>
              <TableCell align="center">S. Wygrane</TableCell>
              <TableCell align="center">Gemy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewItems.map((player, i) => (
              <TableRow key={i} style={{ height: "61px" }}>
                <TableCell align="right">
                  <span>
                    {i + 1 + (page || 0) * (pageSize || 25)}
                  </span>
                </TableCell>
                <TableCell>
                  <span>
                    {player.name}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span>{player.score.points}</span>
                </TableCell>
                <TableCell align="center">
                  <span>{player.score.matchesPlayed}</span>
                </TableCell>
                <TableCell align="center">
                  <span>{player.score.matchesWon}</span>
                </TableCell>
                <TableCell align="center">
                  <span>{player.score.setsPlayed}</span>
                </TableCell>
                <TableCell align="center">
                  <span>{player.score.setsWon}</span>
                </TableCell>
                <TableCell align="center">
                  <span>
                    {player.score.gemsWon} : {player.score.gemsLost}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={orderedPlayers.length}
        rowsPerPage={pageSize ?? 25}
        page={page ?? 0}
        onChangePage={(e, p: number) => {
          setPage(p);
        }}
        onChangeRowsPerPage={(e) => {
          setPageSize(parseInt(e.target.value));
        }}
      />
    </>
  );
};

export default TournamentPlayersStats;