import React from "react";
import { Grid, Checkbox, Link, Divider, TextField, InputAdornment, Tooltip, Typography } from "@material-ui/core";
import { AdditionalSignupModel } from "../../store/signup/models";
import HelpIcon from "@material-ui/icons/Help";

interface AdditionalSubmissionProps {
    model: AdditionalSignupModel,
    onModelChanged: (model: AdditionalSignupModel) => void;
}

const AdditionalSubmission: React.FC<AdditionalSubmissionProps> = ({ model, onModelChanged }) => {

    const setTocApproved = (approved: boolean) => onModelChanged({ ...model, tocApproved: approved });
    const setPromo = (promo: string) => onModelChanged({ ...model, promo });
    const setReferral = (referral: string) => onModelChanged({ ...model, referral })

  return (
    <Grid container direction="column">
      <Grid item>
        <Checkbox
          checked={model.tocApproved}
          onChange={(e) => setTocApproved(e.target.checked)}
        ></Checkbox>
        Zgadzam się z{" "}
        <Link href="Smart_Tennis_regulamin.pdf" target="_blank" color="primary">
          regulaminem ligi
        </Link>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              label="Kod promocyjny"
              variant="outlined"
              value={model.promo}
              onChange={(e) => setPromo(e.target.value)}
            />
          </Grid>
          <Grid item>
          <TextField
                  label="Kod polecającego"
                  variant="outlined"
                  value={model.referral}
                  onChange={(e) => setReferral(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={<Typography variant="body2">Kod, który dostałeś/-aś od osoby, która zaprosiła Cię do ligi. Składa się z 8-miu dużych liter i cyfr, np. 85M3JUQ1</Typography>}>
                          <HelpIcon
                            color="primary"
                            fontSize="small"
                            style={{ marginLeft: "4px" }}
                          ></HelpIcon>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdditionalSubmission;
