import React from "react";
import { TournamentHeaderWithUserAssignment as Model } from "../../store/tournaments/models";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Grid,
  Typography,
  Hidden,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DateView from "../../framework/components/DateView";
import { Link } from "react-router-dom";
import DateTimeView from "../../framework/components/DateTimeView";
import ReactMarkdown from "react-markdown";

interface TournamentHeaderProps {
  tournament: Model;
}

const TournamentHeader: React.FC<TournamentHeaderProps> = ({ tournament }) => (
  <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="body1" color={"secondary"}>
            {tournament.name}
          </Typography>
        </Grid>
        <Hidden xsDown={true}>
          <Grid item sm={6} md={3}>
            <Typography variant="body2">
              <b>Data:</b> <DateTimeView value={tournament.date}></DateTimeView>
            </Typography>
          </Grid>
        </Hidden>
        <Hidden xsDown={true}>
          <Grid item sm={"auto"} md={3}>
            <Typography variant="body2">
              <b>Uczestnicy:</b> {tournament.activePlayers}{" "}
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Grid container spacing={2} style={{ marginRight: "28px" }}>
        <Grid item sm={12} md={6}>
          <Typography variant="body2">
            <b>Opis</b>
          </Typography>
          <ReactMarkdown source={tournament.description}></ReactMarkdown>
        </Grid>
        <Grid item sm={12} md={3}>
          <Typography variant="body2">
            <b>Nagrody</b>
          </Typography>
          <ReactMarkdown
              source={tournament.pricesDescription}
            ></ReactMarkdown>
        </Grid>
        <Grid item sm={12} md={3}>
        <Typography variant="body2">
            <b>Klub</b>
          </Typography>
          <Grid container spacing={3}>
            <img
              src={tournament.club.logoUrl}
              style={{ height: "64px", margin: '8px' }}
              alt={tournament.club.name}
              title={tournament.club.name}
            />
          </Grid>
        </Grid>
      </Grid>
    </ExpansionPanelDetails>
    <ExpansionPanelActions>
        <Button component={Link} to={`/tournaments/${tournament.province}/${tournament.id}`}>
          Szczegóły
        </Button>
        {tournament.userAssigned ? (
          <></>
        ) : (
          <Button
            component={Link}
            color={"primary"}
            to={`/tournaments/${tournament.province}/${tournament.id}/sign-up`}
          >
            <AssignmentIcon />
            Zapisz się
          </Button>
        )}
      </ExpansionPanelActions>
  </ExpansionPanel>
);

export default TournamentHeader;
