import React from "react";
import "./App.css";

import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import { ThemeProvider, Typography, Avatar } from "@material-ui/core";

import "date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";

import theme from "./utils/theme";
import NavBar, { NavBarLink } from "./components/shell/NavBar";
import { Provider } from "react-redux";
import store from "./store/store";
import TopBar from "./components/shell/TopBar";
import HeroImage from "./components/shell/HeroImage";
import PageWrapper from "./components/shell/PageWrapper";
import Page from "./components/shell/Page";

import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

import TournamentsPage from "./components/tournaments/TournamentsPage";
import TournamentSignupPage from "./components/signup/TournamentSignupPage";
import TournamentPage from "./components/tournaments/TournamentPage";
import ErrorNotifier from "./components/shell/ErrorNotifier";
import PaymentsPage from "./components/payments/PaymentsPage";

const links: NavBarLink[] = [
  {
    label: "Turnieje",
    path: "/tournaments",
  },
  {
    label: "Ligi",
    path: "https://liga.smarttennis.pl/",
    isExternal: true
  }
];

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
          <>
            <ErrorNotifier></ErrorNotifier>
            <Router>
              <TopBar></TopBar>
              <NavBar links={links}></NavBar>
              <HeroImage></HeroImage>
              <PageWrapper>
              <Route path="/payments">
                  <Page
                    pageName="Moje płatności"
                    title={<Typography variant="h5">Moje płatności</Typography>}
                    avatar={
                      <Avatar>
                        <AccountBalanceWalletIcon></AccountBalanceWalletIcon>
                      </Avatar>
                    }
                  >
                    <PaymentsPage></PaymentsPage>
                  </Page>
                </Route>
                <Route path="/tournaments/:province/:id/sign-up">
                  <Page
                    pageName="Zapisy na turrniej"
                    title={
                      <Typography variant="h5">Zapisy na turniej</Typography>
                    }
                    avatar={
                      <Avatar>
                        <PersonAddIcon></PersonAddIcon>
                      </Avatar>
                    }
                  >
                    <TournamentSignupPage></TournamentSignupPage>
                  </Page>
                </Route>
                <Route path="/tournaments/:province/:id">
                  <Page
                    pageName="Szczegóły turrnieju"
                    title={
                      <Typography variant="h5">Szczegóły turnieju</Typography>
                    }
                    avatar={
                      <Avatar>
                        <FeaturedPlayListIcon></FeaturedPlayListIcon>
                      </Avatar>
                    }
                  >
                    <TournamentPage></TournamentPage>
                  </Page>
                </Route>
                <Route path="/tournaments">
                  <Page
                    pageName="Aktywne turnieje"
                    title={
                      <Typography variant="h5">Aktywne turnieje</Typography>
                    }
                    avatar={
                      <Avatar>
                        <FormatListNumberedIcon></FormatListNumberedIcon>
                      </Avatar>
                    }
                  >
                    <TournamentsPage></TournamentsPage>
                  </Page>
                </Route>
                <Route path="/">
                  <Redirect to="/tournaments" />
                </Route>
              </PageWrapper>
            </Router>
          </>
        </MuiPickersUtilsProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
