import React, { Props } from "react";

import { Card } from "@material-ui/core";
import { Switch } from "react-router-dom";

const PageWrapper: React.FC = (props: Props<any>) => (
  <Card
    square={true}
    style={{
      position: "relative",
      top: "-100px",
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "16px"
    }}
  >
    <Switch>
        {props.children}
      
    </Switch>
  </Card>
);

export default PageWrapper;