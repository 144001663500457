import { combineReducers } from "redux";
import tournaments, { State as TournamentsState }  from "./tournaments/reducers";
import players, { State as PlayersState } from "./players/reducers";
import signup, { State as SignupState } from "./signup/reducers";
import shell, { State as ShellState } from "./shell/reducers"
import payments, { State as PaymentsState } from "./payments/reducers";

export default combineReducers({ tournaments, players, signup, shell, payments });

export interface AppState {
    tournaments: TournamentsState;
    players: PlayersState;
    signup: SignupState;
    shell: ShellState;
    payments: PaymentsState;
}