import { Data } from "../../framework/data";
import { PaymentInfo } from "../../store/payments/models";
import { useAuth0 } from "../shell/Auth0Context";
import React from "react";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { getUserPayments, redoUserPayment } from "../../store/payments/actions";
import { connect } from "react-redux";
import PaymentsList from "./PaymentsList";
import Loading from "../../framework/components/Loading";

interface PaymentsPageProps {
  payments: Data<PaymentInfo[]>;
  loadPayments: (token: string) => void;
  redoPayment: (token: string, payment: PaymentInfo) => void;
}

const PaymentsPage: React.FC<PaymentsPageProps> = ({
  payments,
  loadPayments,
  redoPayment,
}) => {
  const { getTokenSilently, isAuthenticated } = useAuth0();

  React.useEffect(() => {
    if (isAuthenticated) {
      getTokenSilently().then((x: string) => {
        loadPayments(x);
      });
    }
  }, [isAuthenticated]);

  const onRedoPayment = async (p: PaymentInfo) => {
    var token = await getTokenSilently();
    redoPayment(token, p);
  };

  return (
    <>
      {payments.type === "LOADED" ? (
        <PaymentsList
          payments={payments.value}
          redoPayment={onRedoPayment}
        ></PaymentsList>
      ) : (
        <>
        <Loading></Loading>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  payments: state.payments.payments,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadPayments: (token: string) => dispatch(getUserPayments(token)),
  redoPayment: (token: string, payment: PaymentInfo) =>
    dispatch(redoUserPayment({ token, payment })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPage);
