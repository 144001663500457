import { PaymentInfo, PaymentInfoResponse, PaymentOrder } from "./models";
import { getConfig } from "../../config/configuration";

export async function loadPlayersPaymentsApi(token: string): Promise<PaymentInfo[]> {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/players/me/payments`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    if(response.ok) {
        const itemsResponse: PaymentInfoResponse[] = await response.json();
        return itemsResponse.map(x => ({ ...x, paymentDate: new Date(x.paymentDate) }));
    } else {
        throw new Error(response.statusText);
    }
}

export async function redoUserPaymentApi(token: string, externalPaymentOrderId: string): Promise<PaymentOrder> {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/payments/${externalPaymentOrderId}/copy`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'POST'
    });

    if(response.ok) {
        return await response.json();
    } else {
        throw new Error(response.statusText);
    }
}