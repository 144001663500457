import React from "react";
import { Data } from "../../framework/data";
import { TournamentHeader, TournamentHeaderWithUserAssignment } from "../../store/tournaments/models";
import { groupBy, map } from "ramda";
import { TournamentsGroup } from "./TournamentsGroup";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { loadTournaments, loadMyTournaments } from "../../store/tournaments/actions";
import { connect } from "react-redux";
import { useAuth0 } from "../shell/Auth0Context";
import { tournamentsWithCurrentUser } from "../../store/tournaments/selectors";
import Loading from "../../framework/components/Loading";

interface TournamentsPageProps {
  tournaments: Data<TournamentHeaderWithUserAssignment[]>;
  loadTournaments: () => void;
  loadSubscribedTournaments: (province: number, token: string) => void;
}

const TournamentsPage: React.FC<TournamentsPageProps> = ({
  tournaments,
  loadTournaments,
  loadSubscribedTournaments
}) => {
  React.useEffect(() => {
    if (loadTournaments) loadTournaments();
  }, []);

  const { isAuthenticated,  getTokenSilently } = useAuth0();

  React.useEffect(() => {

    async function getSubscribedTournaments() {
      if(isAuthenticated) {
        const token = await getTokenSilently();
        loadSubscribedTournaments(5, token);
      }
    };

    getSubscribedTournaments();

  }, [isAuthenticated])

  return (
    <>
      {tournaments.type === "LOADED" ? (
        Object.entries(
          groupBy(
            (x) => x.province.toString(),
            tournaments.value
          )
        ).map(([province, tournaments]) => (
          <TournamentsGroup
            key={province}
            province={parseInt(province)}
            tournaments={tournaments}
          ></TournamentsGroup>
        ))
      ) : (
        <Loading />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
    tournaments: tournamentsWithCurrentUser(state) 
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadTournaments: () => dispatch(loadTournaments({ province: 5 })),
    loadSubscribedTournaments: (province: number, token: string) => dispatch(loadMyTournaments({ province, token }))
});

export default connect(mapStateToProps, mapDispatchToProps)(TournamentsPage);
