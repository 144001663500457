import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Auth0Provider } from "./components/shell/Auth0Context";
import history from "./utils/history";

const onRedirectCallback = (appState:any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={"tennis-league.eu.auth0.com"}
    client_id={"y2mG24uEjR8lf0GS527gUx6UpcsySFxD"}
    redirect_uri={window.location.origin}
    audience={"https://tennis-league/api"}
    onRedirectCallback={onRedirectCallback}
  >
      <React.StrictMode>
      <App />
    </React.StrictMode>
  </Auth0Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
