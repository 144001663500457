import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { Data } from "../../framework/data";
import { Player } from "../../store/players/models";
import { loadCurrentPlayer } from "../../store/players/actions";
import { useAuth0 } from "../shell/Auth0Context";
import TournamentSignup from "./TournamentSignup";
import { useParams } from "react-router-dom";
import { Tournament } from "../../store/tournaments/models";
import { loadCurrentTournament } from "../../store/tournaments/actions";
import Loading from "../../framework/components/Loading";

interface TournamentSignupPageProps {
  currentPlayer: Data<Player>;
  currentTournament: Data<Tournament>;
  loadTournament: (province: number, id: string ) => void;
  loadPlayer: (token: string) => void;
}

const TournamentSignupPage: React.FC<TournamentSignupPageProps> = ({
  currentPlayer,
  currentTournament,
  loadPlayer,
  loadTournament
}) => {
  const { isAuthenticated, getTokenSilently, loginWithRedirect } = useAuth0();

  const { province, id } = useParams();

  React.useEffect(() => {

    loadTournament(province, id);

  }, [province, id]);

  React.useEffect(() => {
    if (currentPlayer.type === "NOT_STARTED") {
      (getTokenSilently() as Promise<string>).then((token: string) => {
        loadPlayer(token);
      }).catch(() => {
        loginWithRedirect({ redirect_uri: window.location.href });
      });
    }
  }, [isAuthenticated]);

  return isAuthenticated === false ? (<>
    <p>Jesteś niezalogowany. Przenosimy Cię do strony logowania...</p>
    <Loading></Loading>
  </>) : (currentPlayer.type === "LOADED" && currentTournament.type === "LOADED") ? (
    <TournamentSignup currentPlayer={currentPlayer.value} currentTournament={currentTournament.value}></TournamentSignup>
  ) : (
    <Loading />
  );
};

const mapStateToProps = (state: AppState) => ({
  currentPlayer: state.players.current,
  currentTournament: state.tournaments.current
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadPlayer: (token: string) => dispatch(loadCurrentPlayer({ token })),
  loadTournament: (province: number, id: string ) => dispatch(loadCurrentTournament({ province, id }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TournamentSignupPage);
