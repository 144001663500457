import { createMuiTheme } from '@material-ui/core/styles';
import { lightGreen, orange } from "@material-ui/core/colors";
import { plPL } from '@material-ui/core/locale';
//import L from 'leaflet';

const theme = createMuiTheme({
    palette: {
        primary: lightGreen,
        secondary: orange
    },
    overrides: {
        MuiTabs: {
            indicator: {
                height: '4px'
            }
        }
    }
}, plPL);

//delete (<any>L.Icon.Default).prototype._getIconUrl;

export default theme;