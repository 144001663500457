import React from "react";
import { Player } from "../../store/players/models";
import {
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Divider
} from "@material-ui/core";
import PlayerProfile from "../players/PlayerProfile";
import AdditionalSubmission from "./AdditionalSubmission";
import { AdditionalSignupModel } from "../../store/signup/models";
import { Tournament } from "../../store/tournaments/models";
import SubmissionPaymentDetails from "./SubmissionPaymentDetails";
import SignupLogs from "./SignupLogs";

interface TournamentSignupProps {
  currentPlayer: Player;
  currentTournament: Tournament;
}

const createStep = (idx: number, props: any) => {
    switch(idx) {
        case 0: return <PlayerProfile {...props} ></PlayerProfile>
        case 1: return <AdditionalSubmission {...props}></AdditionalSubmission>
        case 2: return <SubmissionPaymentDetails {...props}></SubmissionPaymentDetails>
        case 3: return <SignupLogs {...props}></SignupLogs>
    }
}



const getTitles = () => [
    "Dane zawodnika",
    "Regulamin i kody promocyjne",
    "Informacja o płatności",
    "Zapis do turnieju"
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    container: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    divider: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  })
);

const TournamentSignup: React.FC<TournamentSignupProps> = ({
  currentPlayer,
  currentTournament
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [player, setPlayer] = React.useState<Player>(currentPlayer);
  const [additionalSubmissionModel, setAdditionalSubmissionModel] = React.useState<AdditionalSignupModel>({ promo: "", referral: "", tocApproved: false });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validate = (step: number) => {
    switch(step) {
      case 0: return player && player.birthDate && player.email && player.gender && player.level && player.name && player.phoneNumber && player.surname;
      case 1: return additionalSubmissionModel && additionalSubmissionModel.tocApproved;
      case 2: return true;
      case 3: return true;
      default: return false;
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="horizontal">
        {getTitles().map((title) => (
          <Step key={title}>
            <StepLabel>{title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.container}>
        <div>{createStep(activeStep, {
            player,
            onPlayerChanged: (p: Player) => setPlayer(p),
            model: additionalSubmissionModel,
            onModelChanged: (m: AdditionalSignupModel) => setAdditionalSubmissionModel(m),
            tournament: currentTournament,
            promoCode: additionalSubmissionModel.promo
        })}</div>
        <Divider className={classes.divider}></Divider>
        <div>
          <Button
            disabled={activeStep === 0 || activeStep === (getTitles().length - 1)}
            onClick={handleBack}
            className={classes.button}
          >
            Wstecz
          </Button>
          {
            activeStep !== getTitles().length - 1 ?
            (<Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
              disabled={!validate(activeStep)}
            >
              {activeStep === getTitles().length - 2 ? "Zapisz się" : "Dalej"}
            </Button>) : <></>
          }
          
        </div>
      </div>
    </div>
  );
};

export default TournamentSignup;
