import React from "react";
import { Player } from "../../store/players/models";
import { Data } from "../../framework/data";
import { TournamentWithUserAssigned } from "../../store/tournaments/models";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { loadCurrentTournament, loadMyTournaments } from "../../store/tournaments/actions";
import { connect } from "react-redux";
import { tournamentwithCurrentUser } from "../../store/tournaments/selectors";
import { useParams } from "react-router-dom";
import { useAuth0 } from "../shell/Auth0Context";
import TournamentDetails from "./TournamentDetails";
import Loading from "../../framework/components/Loading";

interface TournamentPageProps {
    player: Data<Player>;
    tournament: Data<TournamentWithUserAssigned>;
    loadTournament: (id: string, province: number) => void;
    loadSubscribedTournaments: (province: number, token: string) => void;
}

const TournamentPage: React.FC<TournamentPageProps> = ({ player, tournament, loadSubscribedTournaments, loadTournament }) => {

    const { province, id } = useParams();
    const { isAuthenticated,  getTokenSilently } = useAuth0();

    React.useEffect(() => {
        loadTournament(id, province);
    }, [id, province]);

    React.useEffect(() => {

        async function getSubscribedTournaments() {
          if(isAuthenticated) {
            const token = await getTokenSilently();
            loadSubscribedTournaments(province, token);
          }
        };
    
        getSubscribedTournaments();
    
      }, [isAuthenticated])

    return (<>
    {
        tournament.type === "LOADED" 
        ? (<TournamentDetails tournament={tournament.value}></TournamentDetails>)
        : <Loading />
    }
    
    </>);
};

const mapStateToProps = (state: AppState) => ({
    player: state.players.current,
    tournament: tournamentwithCurrentUser(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadTournament: (id: string, province: number) => dispatch(loadCurrentTournament({ id, province })),
    loadSubscribedTournaments: (province: number, token: string) => dispatch(loadMyTournaments({ province, token }))
});

export default connect(mapStateToProps, mapDispatchToProps)(TournamentPage);