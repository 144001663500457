export const resolveEnvironment = (url: string) => {
    if(url.indexOf("localhost") !== -1) {
        return "local";
    } else {
        return "prod";
    }
}

let config: any = undefined;

export const getConfig = async (useLocal: boolean = false) => {

    if(useLocal) {
        return undefined;
    }

    var envName = resolveEnvironment(window.location.host);

    if(!config) {
        const m = await import('./' + envName);
        config = m.default;
    }

    return config;
    
}